import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Constants } from '@core/constants/sso.constants';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable, of } from 'rxjs';
import { UserSession } from '@core/models/usersession.interface';
import SSO = Constants.SSO;
import { Enums } from '@core/Enums/token-type.enum';
import { CacheService } from './cache.service';
import { ApiUrlConstants } from '@core/constants/api.constants';
import { CookieService } from 'utils/cookie.service';

declare let apiConfig: any;

@Injectable({ providedIn: 'root' })
export class SsoService {
  constructor(
              private http: HttpClient,
              private localStorageService: LocalStorageService,
              private cacheService: CacheService) {
  }

  public getSSOLoginUrl(): string {
    const state = this.getNonce();
    const idNonce = this.getNonce();
    CookieService.setCookie(SSO.NONCE, idNonce, null, environment.cookiePath, environment.cookieDomain);
    this.localStorageService.set('state', state);
    return environment.ssoApiURL + '/auth?client_id=' + environment.ssoClientID + '&redirect_uri=' + Constants.SSO.LOGIN_REDIRECT_URL +
      '&scope=openid&response_type=code&state=' + state + '&nonce=' + idNonce;
  }

  public ssoRedirect(redirectUrl: string): void {
    window.location.href = redirectUrl;
  }

  public getSSOLogoutUrl(redirectUri?: string): string {
    return Constants.SSO.LOGOUT_URL + '?client_id=' + Constants.SSO.CLIENT_ID + '&redirect_uri=' + (redirectUri || Constants.SSO.CURRENT_URL);
  }

  public getCheckLoginFlags() {
    return '&chc=true' + '&actual_url=' + window.location.pathname.split('home')[0] + 'products';
  }

  public getNonce(): string {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now();
    }
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });

    return uuid;
  }

  public processCodeToken(): Observable<any> {
    const tokenUrl = environment.ssoApiURL + '/token';
    const code: string = this.getParamValueFromUrl('code', window.location.href);
    const state: string = this.getParamValueFromUrl('state', window.location.href);
    if (state === JSON.parse(localStorage.getItem('appflp360.state')) && code) {
      // Construct request headers
      const requestHeaders = new HttpHeaders()
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/x-www-form-urlencoded');

      // Construct request Body
      const payload = new HttpParams()
        .set('client_id', environment.ssoClientID)
        .set('client_assertion_type', 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer')
        .set('client_assertion', atob(apiConfig.ssoClientAssertion))
        .set('code', code)
        .set('grant_type', 'authorization_code')
        .set('redirect_uri', Constants.SSO.LOGIN_REDIRECT_URL);

      const options = {
        headers: requestHeaders
      };
      return this.http.post(tokenUrl, payload, options);
    } else {
      CookieService.deleteCookie(SSO.NONCE);
      localStorage.removeItem('appflp360.state');
      window.location.href = this.getSSOLoginUrl();
    }
  }

  public processIntrospect(accessToken: any,apiConfiguration): Observable<any> {
    const introspectUrl = environment.ssoApiURL + '/token/introspect';

    // Construct body
    const payload = new HttpParams()
      .set('client_id', environment.ssoClientID)
      .set('client_assertion_type', 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer')
      .set('client_assertion', atob(apiConfiguration.ssoClientAssertion))
      .set('token_type_hint', 'access_token')
      .set('token', accessToken);

    // Construct headers
    const requestHeaders = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(introspectUrl, payload, { headers: requestHeaders, responseType: 'text' });
  }

  getConfigDataforUniqueClientAssertion(): Promise<any> {
    let configUrl = environment.production ? environment.baseHref : environment.baseUrl;
    configUrl = configUrl + '/api/Configuration?expiryCode=' + new Date().getTime();
    return this.http.get(configUrl)
      .toPromise();
  }

  public processRefreshToAccessToken(apiConfiguration): Observable<any> {
    const tokenUrl = environment.ssoApiURL + '/token';

    // Construct request headers
    const requestHeaders = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/x-www-form-urlencoded');

    // Construct request Body
    const payload = new HttpParams()
      .set('client_id', environment.ssoClientID)
      .set('client_assertion_type', 'urn:ietf:params:oauth:client-assertion-type:jwt-bearer')
      .set('client_assertion', atob(apiConfiguration.ssoClientAssertion))
      .set('grant_type', 'refresh_token')
      .set('refresh_token', this.localStorageService.get(environment.refreshToken))
      .set('redirect_uri', Constants.SSO.LOGIN_REDIRECT_URL);

    const options = {
      headers: requestHeaders
    };

    return this.http.post(tokenUrl, payload, options);
  }

  public checkIfLoginRequired(): boolean {
    let loginRequired = true;
    const memberSessionId = CookieService.getCookieValue(SSO.MEMBER_SESSIONID);
    const localMemberSessionId = this.localStorageService.get(SSO.LOCAL_MEMBER_SESSION_ID);
    const accessToken = this.localStorageService.get(environment.accessToken);
    if (memberSessionId) {
      if (accessToken && (memberSessionId === localMemberSessionId)) {
        loginRequired = false;
      }
    }
    return loginRequired;
  }


  public getStoredToken(tokenType: Enums.TokenType) {
    let token = '';
    let tokenKey = '';
    if (tokenType === Enums.TokenType.ACCESS_TOKEN) {
      tokenKey = environment.accessToken;
    } else {
      tokenKey = environment.refreshToken;
    }
    const memberSessionId = CookieService.getCookieValue(SSO.MEMBER_SESSIONID);
    const localMemberSessionId = this.localStorageService.get(SSO.LOCAL_MEMBER_SESSION_ID);
    if (memberSessionId) {
      if (memberSessionId === localMemberSessionId) {
        token = this.localStorageService.get(tokenKey);
      }
    }
    return token;
  }

  public saveTokens(data: any): void {
    const uuid = this.getNonce();
    CookieService.setCookie(SSO.MEMBER_SESSIONID, uuid, null, environment.cookiePath, environment.cookieDomain);
    //FLP360-3689: Added cookie to made availble FLP360 distributor's session, this used to get and delete session from Ecommerce portal
    CookieService.setCookie(SSO.FLP360_MEMBER_SESSION_STATE,data?.session_state,null, environment.cookiePath, environment.cookieDomain);
    this.localStorageService.set(SSO.LOCAL_MEMBER_SESSION_ID, uuid);
    this.localStorageService.set(environment.accessToken, data.access_token);
    this.localStorageService.set(environment.refreshToken, data.refresh_token);
    this.localStorageService.set(SSO.IS_NEW_TOKEN, 'true');
  }

  public constructUserSession(accessToken: string, refreshToken: string, uuid: string): UserSession {
    const userSession: UserSession = {
      accessToken,
      refreshToken,
      environment: environment.envName,
      guid: uuid
    };
    return userSession;
  }

  public saveUserAccessToken(userSession: UserSession): void {
    const accountServiceUrl = environment.apiUrl + '/account/v1/sessions';
    this.http.post(accountServiceUrl, userSession).subscribe(response => {
      if (response) {
        this.localStorageService.set(SSO.IS_NEW_TOKEN, 'false');
      }
    }, error1 => {
      // TODO: handle error
    });
  }

  public getUserAccessToken(uuid: string): Observable<any> {
    const accountServiceUrl = environment.apiUrl + '/account/v1/sessions/' + uuid;
    return this.http.get(decodeURIComponent(encodeURIComponent(accountServiceUrl)));
  }

  public checkAndFetchUserSessionDetails(): Observable<UserSession> {
    const memberSessionId = CookieService.getCookieValue(SSO.MEMBER_SESSIONID);
    const localMemberSessionId = this.localStorageService.get(SSO.LOCAL_MEMBER_SESSION_ID);
    if (memberSessionId && memberSessionId !== localMemberSessionId) {
      return this.getUserAccessToken(memberSessionId);
    } else {
      return of(null);
    }
  }

  /** * This method used for logout from system * logOutSettings * @memberof AppHeaderComponent */
  logOutSettings(): void {
    CookieService.deleteCookie(SSO.NONCE);
    CookieService.deleteCookie(SSO.MEMBER_SESSIONID);
    CookieService.deleteCookie(SSO.FLP360_MEMBER_SESSION_STATE);
    CookieService.deleteCookie(SSO.FLP360_MEMBER_ID);
    this.cacheService.removeAll();
    sessionStorage.clear();
  }

  // TODO have to keep in common
  getParamValueFromUrl(searchParam: string, urlString: string) {
    urlString=decodeURIComponent(encodeURIComponent(urlString));
    const match = RegExp('[?&]' + searchParam + '=([^&]*)').exec(urlString);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
  }
  getNonTitanUserInfo(userId) {
    return this.http.get<any>(
      ApiUrlConstants.REPORTS_BASE + 'user-profile/distributorId/' + userId);
  }
}
