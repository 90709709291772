import { environment } from '../../../environments/environment';
export class ApiUrlConstants {
  public static REPORTS_BASE = environment.apiUrl + '/' + environment.reportApi + '/V2/';
  public static ACCOUNT_BASE = environment.apiUrl + '/account/v1/';
  public static ACCOUNT_WIDGET = ApiUrlConstants.ACCOUNT_BASE + 'flp360/widget/';
  public static REPORTS_DISTRIBUTOR = ApiUrlConstants.REPORTS_BASE + 'distributors/';
  public static REPORTS_DISTRIBUTOR_ID = ApiUrlConstants.REPORTS_BASE + 'distributorId/';
  public static CHARGEBEE_PLANS_LIST = environment.apiUrl + '/payment/v1/chargebee/plans';
  public static CHARGEBEE_CREATE_SUBSCRIPTION = environment.apiUrl + '/payment/v1/chargebee/create/subscription';
  public static FLP360_ENABLED_TITAN = environment.apiUrl + '/account/V1/flp360/fbos/';
  public static FLP360_ENABLE_KEYCLOAK = environment.baseUrl + '/api/create/keycloak-user';
  public static FLP360_INCENTIVES_REPORT = ApiUrlConstants.REPORTS_BASE + 'performance/fboId/';
  public static SOCIAL_SALES_CREATE_SUBSCRIPTION = ApiUrlConstants.ACCOUNT_BASE + 'flp360/create/user/socialsales-account';
  public static FLP360_MEMBER_SUBSCRIPTION_CREATION = ApiUrlConstants.ACCOUNT_BASE + 'flp360/fbo/member-subscription';
  public static SOCIAL_SALES_AUTH_TOKEN = environment.socialsalesBaseUrl + 'Auth/Token';
  public static UPDATE_FLP360_KEYCLOAK_STATUS = ApiUrlConstants.ACCOUNT_BASE + 'flp360/update-status';
  public static CMS_API_URL = environment.apiUrl + '/content/v1/';
  public static CUSTOMER_SERVICE_API = environment.apiUrl + '/customer/v1/';
  public static CDN_BASE = environment.cdnURL + '/portals/flp360/';
  public static ANNOUNCEMENTS_URL = ApiUrlConstants.CDN_BASE + 'template1/announcements/';
  public static ANNOUNCEMENT_SETTINGS = ApiUrlConstants.CDN_BASE + 'template1/announcements/flp360_settings.json';
  public static ANNOUNCEMENT_VERSION_UPDATE_URL = ApiUrlConstants.ACCOUNT_BASE + 'flp360/distributors/';
  public static ENROLLMENT_TOOLS_VIDEO_URL = ApiUrlConstants.CDN_BASE+ 'template1/videoTutorials/enrollment-video/';
  public static CONFIGURATION_API = environment.apiUrl + '/admin/V1/';
  public static CCSUMMARY_API =  ApiUrlConstants.REPORTS_BASE + 'fboId/';
  public static CUSTOMER_SERVICE_V2_API = environment.apiUrl + '/customerv2/V1/';
  public static ECOMMERCE_URL = environment.ecommerceUrl + '/api/';
}
